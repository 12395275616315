globalThis["__sentryRewritesTunnelPath__"] = "/monitoring";
globalThis["SENTRY_RELEASE"] = {"id":"e2b8203fbc9afa007a095399a5706010b15c7821"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

Sentry.init({
  dsn: "https://cfc8a98df658221c6bba7c4e2b1c8b6e@o4507832277270528.ingest.de.sentry.io/4507832291688528",

  environment: process.env.ENVIRONMENT || "development",
  enabled:
    process.env.ENVIRONMENT !== void 0 &&
    process.env.ENVIRONMENT !== "development",
  // Add optional integrations for additional features
  integrations: [Sentry.replayIntegration()],

  // Define how likely traces are sampled. Adjust this value in production, or use tracesSampler for greater control.
  tracesSampleRate: 1,

  // Define how likely Replay events are sampled.
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // Define how likely Replay events are sampled when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,
});
